import { Image } from "./image";
import React from "react";
import useWindowDimensions from "../useWindowDimensions";
import Image1 from "../Images/eat-well/menu/ECONOMY1.jpeg"
import Image2 from "../Images/eat-well/menu/ECONOMY2.jpeg"
import Image3 from "../Images/eat-well/menu/ECONOMY3.jpeg"
import Image4 from "../Images/eat-well/menu/ECONOMY4.jpeg"
import Image5 from "../Images/eat-well/menu/Executive1.jpeg"
import Image6 from "../Images/eat-well/menu/Executive2.jpeg"
import Image7 from "../Images/eat-well/menu/Executive3.jpeg"
import Image8 from "../Images/eat-well/menu/Executive4.jpeg"
import Image9 from "../Images/eat-well/menu/EconomyMonthIymenu.jpeg"
import Image10 from "../Images/eat-well/menu/ExecutiveMonthIymenu.jpeg"


// import Image2 from "../Images/eat-well/image2.jpeg"
// import Image3 from "../Images/eat-well/image5new.png"
// import Image4 from "../Images/eat-well/image4.jpeg"




export const Gallery = (props) => {
  const { width } = useWindowDimensions();

  return (
    <div id="portfolio" className="text-center" 
    style={{borderBottom:"1px solid #41881a ",borderTop:"1px solid #41881a",width:"100%"}}
    >
      <div className="container" >
        <div className="section-title" style={{marginTop:width<500?-80:0}}>
          <h2 style={{color:"#f37030 ",fontSize:width<500?30:""}}>Menu Weekly Economy</h2>
          <p style={{color:"black"}}>
           {/* Economy */}
          </p>
        </div>
        
        <div className="row" style={{marginTop:width<500?-50:0}}>
          <div className="portfolio-items">
             <div data-aos={width>500?"fade-right":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image1}
                      smallImage={Image1}
                    />
                  </div>
          <div data-aos={width>500?"fade-left":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image2}
                      smallImage={Image2}
                    />
                  </div>
                  
                  <div data-aos={width>500?"fade-right":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image3}
                      smallImage={Image3}
                    />
                  </div>
                  <div data-aos={width>500?"fade-left":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image4}
                      smallImage={Image4}
                    />
                  </div>
                 
                  
          </div>
        </div>
        {/* <div className="section-title" style={{marginTop:100}}> */}
        <div className="section-title" style={{marginTop:width<500?30:80}}>

          <h2 style={{color:"#f37030 ",fontSize:width<500?30:""}}>Menu Weekly Exective</h2>
          <p style={{color:"black"}}>
           {/* Economy */}
          </p>
        </div>
        
        {/* <div className="row"> */}
        <div className="row" style={{marginTop:width<500?-50:0}}>

          <div className="portfolio-items">
             <div data-aos={width>500?"fade-right":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image5}
                      smallImage={Image5}
                    />
                  </div>
          <div data-aos={width>500?"fade-left":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image6}
                      smallImage={Image6}
                    />
                  </div>
                  
                  <div data-aos={width>500?"fade-right":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image7}
                      smallImage={Image7}
                    />
                  </div>
                  <div data-aos={width>500?"fade-left":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image8}
                      smallImage={Image8}
                    />
                  </div>
                 
                  
          </div>
        </div>
        {/* <div className="section-title" style={{marginTop:100}}> */}
        <div className="section-title" style={{marginTop:width<500?30:80}}>

          <h2 style={{color:"#f37030 ",fontSize:width<500?30:""}}>Monthly Menu </h2>
          <p style={{color:"black"}}>
           {/* Economy */}
          </p>
        </div>
        
        {/* <div className="row"> */}
        <div className="row" style={{marginTop:width<500?-50:0}}>

          <div className="portfolio-items">
             <div data-aos={width>500?"fade-right":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image9}
                      smallImage={Image9}
                    />
                  </div>
          <div data-aos={width>500?"fade-left":""}
                    key={"See Full Image"}
                    className="col-sm-6 col-md-6 col-lg-6"
                    
                  >
                    <Image
                      title={"See Full Image"}
                      largeImage={Image10}
                      smallImage={Image10}
                    />
                  </div>
                  
                 
                 
                 
                  
          </div>
        </div>
      </div>
    </div>
  );
};
