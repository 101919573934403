import React from "react";
import useWindowDimensions from "../useWindowDimensions";


export const Image = ({ title, largeImage, smallImage }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
          {/* <div className="hover-text">
            <h4>{title}</h4>
          </div> */}
          <img src={smallImage} style={{border:"2px solid #f37030",boxShadow:"7px 10px 5px #D3D3D3",height:width<500?"":"100%",width:width<500?"90%":"99%",
            marginLeft:width<500?"5%":"",marginTop:width<500?"5%":"3%",borderRadius:5}} className="img-responsive" alt={title} />{" "}
        </a>{" "}
      </div>
    </div>
  );
};
