import { useState } from "react";
import emailjs from "emailjs-com";
import useWindowDimensions from "../useWindowDimensions";

import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const { width } = useWindowDimensions();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(name, email, message);
    
  //   {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
  //   emailjs
  //     .sendForm("service_7t8klg7", "template_meelln8", e.target, "cX6Bmq-khcKFe5z_p")
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert("Thank you for contacting us !  we will get back to you as soon as possible ")

  //         clearState();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("service_jcntmuj", "template_22eajte", e.target, "VXVL9vyfCxOmTl_h1")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          alert("Thank you for contacting us !  we will get back to you as soon as possible ")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div style={{width:width<500?"90%":"100%"}}>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p style={{color:"white"}}>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        style={{ border: "2px solid #f37030" ,borderRadius:15}}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        style={{ border: "2px solid #f37030" ,borderRadius:15}}

                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="3"
                    placeholder="Message"
                    required
                    style={{ border: "2px solid #f37030" ,borderRadius:15}}

                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg" style={{backgroundColor:"#f37030"}}>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p style={{color:"whITE"}}>
                <span>
                  <i style={{color:"#f37030"}} className="fa fa-map-marker" ></i> Address
                </span>
                {/* {props.data ? props.data.address : "loading"} */}
                Gouri VIP ,Khanna East, Islamabad, Pakistan
              </p>
            </div>
            <div className="contact-item">
              <p style={{color:"whITE"}}>
                <span>
                  <i style={{color:"#f37030"}} className="fa fa-phone"></i> Phone
                </span>{" "}
                {/* {props.data ? props.data.phone : "loading"} */}
                0308-7894109 / 0322-7125377 / 0314-5173845

              </p>
            </div>
            <div className="contact-item">
              <p style={{color:"whITE"}}>
                <span>
                  <i style={{color:"#f37030"}} className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {/* {props.data ? props.data.email : "loading"} */}
                info@eat-well.pk
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {/* <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p style={{color:"#f37030"}}>
          Copyright <span style={{color:"#41881a"}}>&copy; </span> 2024 Eat-well.pk
            {/* <a href="http://FsSoft.pk" rel="nofollow">
            FsSoft.pk
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};
