import React from "react";
import useWindowDimensions from "../useWindowDimensions";


export const Testimonials = (props) => {
  const { width } = useWindowDimensions();

  return (
    <div id="testimonials" style={{width:"100%"}}>
      <div className="container">
        <div className="section-title text-center">
          <h2 style={{color:"#f37030 ",fontSize:width<500?30:""}}>What our clients say</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div data-aos={width>500?i==0?"fade-right":i==1?"fade-up":i==2?"fade-left":i==3?"fade-right":i==4?"fade-up":i==5?"fade-left":i==6?"fade-right":i==7?"fade-up":i==8?"fade-left":"":""} key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                   
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p style={{color:"black "}}>"{d.text}"</p>
                      <div className="testimonial-meta" style={{color:"#f37030 "}}> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
