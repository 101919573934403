import React from "react";
import Logo from "../Images/eat-well/bannerchangenew.jpeg"
import bannerformobile from "../Images/eat-well/bannerformoblie.jpeg" 
import useWindowDimensions from "../useWindowDimensions";

export const Header = (props) => {
  const { width } = useWindowDimensions();

  return (
    
    <header id="header" style={{marginTop:80,width:"100%"}}>
      
      <div className="intro">
        
        <div className="overlay">
          
          {/* <div className="container"> */}
            {/* <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text"> */}
                <img src={width<500?bannerformobile:Logo} height={width<500?"40%": "100%"} width={width<500?"100%":"100%"} style={{marginTop:width<500?"12%":"2.5%"}}></img>
                {/* <h1>
               <span style={{color:"#293788"}}> Khatiba</span> 
              
                </h1> */}
                {/* <h2>
               <span>International Hospital</span>
               
                </h2> */}
                {/* <p>KEEPING YOU WELL</p> */}
                <br/>
                {/* <a
                // hide for some time 15-june-2024
                  // href="#features"
                  href="#about"

                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "} */}
              {/* </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </header>
  );
};
